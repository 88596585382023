import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import {
  isNaN,
} from 'lodash';

import {
  PLANS,
} from 'common/plans';

export default () => {
  const [planId, setPlanId] = useState(PLANS.FREE_30.id);
  const [seatCount, setSeatCount] = useState(1);

  const location = useLocation();

  useEffect(() => {
    const parsed = qs.parse(location.search);
    const selectedPlan = PLANS[parsed.plan];
    if (selectedPlan) {
      setPlanId(selectedPlan.id);
    }
    if (parsed.seatCount) {
      const _seatCount = Math.floor(Number(parsed.seatCount));
      if (!isNaN(_seatCount) && _seatCount > 0) {
        setSeatCount(_seatCount);
      }
    }
  }, [location]);

  return {
    planId,
    seatCount,
  };
};
