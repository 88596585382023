import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import asyncSafe, {asyncSafePromise as asp} from '@gocious/async-safe';

import inviteApi from 'api/inviteApi';
import Notification from 'common/Notification';
import NewUserDetails from './NewUserDetails';
import ProvisionStatus from './ProvisionStatus';
import WorkgroupRegistration from './WorkgroupRegistration';
import { mapTokenError } from './mapTokenError.js';
import './index.less';

class InviteVerificationRouter extends Component {
  constructor(props) {
    super(props);
    asyncSafe(this);
  }
  state = {
    isValidating: true,
    tokenError: '',
    workgroupName: '',
    eTag: '',
  };
  static propTypes = {
    pathPrefix: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
  };
  componentDidMount() {
    this.validateToken();
  }
  validateToken() {
    inviteApi.isValidInviteToken(this.props.token)
      .then(asp((res) => {
        this.setState({
          isValidating: false,
          eTag: res.eTag,
          workgroupName: res.workgroupName,
        });
        if (res.hasIdentity) {
          this.props.history.push(`${this.props.pathPrefix}/workgroup-registration`);
        } else {
          this.props.history.push(`${this.props.pathPrefix}/account-details`);
        }
      }, this))
      .catch(asp(err => {
        this.setState({
          isValidating: false,
          tokenError: mapTokenError(err?.data?.type),
        });
      }, this));
  }
  render() {
    const {
      isValidating,
      tokenError,
      workgroupName,
      eTag,
    } = this.state;
    const {
      pathPrefix,
      token,
    } = this.props;
    if (isValidating) {
      return <div className="invite-notification-comp">
        <Notification className="validation-pending-screen"
          header="Validation in progress..."
          message="Sit tight. We are validating your invite link."
          showLoader />
      </div>;
    }
    if (tokenError) {
      return <div className="invite-notification-comp">
        <Notification className="token-error"
          header={tokenError}
          message="If issue persists, contact your account administrator to obtain a new link." />
      </div>;
    }
    return (
      <Switch>
        <Route exact
          path={`${pathPrefix}/account-details`}
          render={() => (
            <NewUserDetails pathPrefix={pathPrefix}
              eTag={eTag}
              token={token} />
          )} />
        <Route exact
          path={`${pathPrefix}/account-status`}
          render={() => (
            <ProvisionStatus token={token}
              workgroupName={workgroupName} />
          )} />
        <Route exact
          path={`${pathPrefix}/workgroup-registration`}
          render={() => (
            <WorkgroupRegistration token={token}
              workgroupName={workgroupName}
              eTag={eTag} />
          )} />
        <Route render={() => (
          <Redirect to={`${pathPrefix}/account-details`} />
        )} />
      </Switch>
    );
  }
}

export default withRouter(InviteVerificationRouter);
