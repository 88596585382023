import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  IntlProvider,
} from 'react-intl';

import localeSniffer from './localeSniffer';

const ENGLISH_LOCALE = 'en-us';
export default class LocaleManager extends Component {
  constructor(props) {
    super(props);
    this.lang = localeSniffer() || ENGLISH_LOCALE;
  }
  static propTypes = {
    children: PropTypes.node.isRequired,
  };
  render() {
    return (
      <IntlProvider locale={this.lang}
        key={this.lang}
        defaultLocale={ENGLISH_LOCALE}>
        {this.props.children}
      </IntlProvider>
    );
  }
};
