import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import BlockLoader from '@gocious/aphrodite/dist/BlockLoader';
import ajaxHelper from '@gocious/ajax-helper';
import userAdapter from '@gocious/auth-helpers/dist/userAdapter';

import UserContext, { EMPTY_USER } from 'common/UserContext';

const Auth = ({ render }) => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    isLoading: isAuthLoading,
  } = useAuth0();
  const [userInfo, setUserInfo] = useState(EMPTY_USER);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!isAuthLoading) {
      if (isAuthenticated) {
        loadUser();
      } else {
        setIsLoading(false);
      }
    }
  }, [isAuthLoading]);

  const loadUser = () => {
    getAccessTokenSilently({ detailedResponse: true })
      .then((accessDetails) => {
        getIdTokenClaims()
          .then((claims) => {
            const userInfo = {
              userProfile: userAdapter(claims),
              accessToken: accessDetails.access_token,
            };
            setUserInfo(userInfo);
            ajaxHelper.init({
              accessToken: accessDetails.access_token,
              errorHandlers: {},
            });
            setIsLoading(false);
          });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <BlockLoader />}
      {!isLoading &&
        <UserContext.Provider value={{
          user: userInfo,
        }}>
          {render()}
        </UserContext.Provider>}
    </>
  );
};

Auth.propTypes = {
  render: PropTypes.func.isRequired,
};

export default Auth;

