import analytics from '@gocious/analytics';

export default {
  init: (history) => {
    if (process.env.APP_INSIGHT_ENABLED) {
      analytics.init(history, GLOBAL_APP_INSIGHT_KEY);
    }
  },
  trackException: (error) => {
    if (process.env.APP_INSIGHT_ENABLED) {
      analytics.trackException(error);
    }
  },
};
