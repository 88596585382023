import React from 'react';
import PropTypes from 'prop-types';
import analytics from 'common/analytics';

import './index.less';

export const withBoundary = (WrappedComponent) => {
  const Bounded = (props) => {
    return (
      <RouteErrorBoundary>
        <WrappedComponent {...props} />
      </RouteErrorBoundary>
    );
  };
  return Bounded;
};

export default class RouteErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };
  state = {
    hasError: false,
  };
  componentDidCatch(error) {
    this.setState({ hasError: true });
    analytics.trackException(error);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className="route-error-boundary-comp">
          <div className="route-error-boundary-stage">
            <h2><i className="icon-notice"></i>&nbsp;Sorry, we thought that was going to work.</h2>
            <p className="come-back-later">Please try and do that again later.<br/>We have logged this issue and will try to make sure you don't see this message again.</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
