import { size } from 'lodash';

const containsUpperCase = /[A-Z]/;
const containsDigit = /[0-9]/;
const containsSymbol = /[!@#$%^&*]/;
const moreThanTwoIdenticalConsecChars = /([a-z0-9!@#$%^&*])\1{2,}/;

export default (password) => {
  return {
    lessThanEightChars: size(password) < 8,
    moreThanTwoIdenticalConsecChars: size(password) === 0 || moreThanTwoIdenticalConsecChars.test(password),
    needsUpperCase: !containsUpperCase.test(password),
    needsSymbol: !containsSymbol.test(password),
    needsDigit: !containsDigit.test(password),
  };
};
