import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import {
  toLower,
  isEmpty,
  map,
} from 'lodash';
import { FormLabel } from '@gocious/aphrodite/dist/FormLayout';
import TextInput from '@gocious/aphrodite/dist/TextInput';
import asyncSafe, {asyncSafePromise as asp} from '@gocious/async-safe';

import signupApi from 'api/signupApi';
import subdomainValidator from './subdomainValidator';

import './index.less';

export default class SubdomainSection extends Component {
  constructor(props) {
    super(props);
    this.checkSubdomainAvailability = debounce(this.checkSubdomainAvailability, 750, {leading: false, trailing: true});
    asyncSafe(this);
  }
  static propTypes = {
    subdomain: PropTypes.string.isRequired,
    subdomainInputError: PropTypes.string,
    onSubdomainChange: PropTypes.func.isRequired,
    onValidationChange: PropTypes.func.isRequired,
    token: PropTypes.string,
  };
  state = {
    isSubdomainAvailable: false,
    subdomainErrors: [],
  };

  displaySubdomainErrors(subdomainErrors) {
    const {
      subdomainInputError,
    } = this.props;
    if (subdomainInputError) {
      return (
        <div key={subdomainInputError}>
          <span className="subdomain-errors">{subdomainInputError}</span><br/>
        </div>
      );
    }
    return map(subdomainErrors, (error) => {
      return (
        <div key={error}>
          <span className="subdomain-errors">{error}</span><br/>
        </div>
      );
    });
  }
  onSubdomainChange(subdomainInput) {
    this.setState({
      isSubdomainAvailable: false,
      subdomainErrors: [],
    });
    const subdomain = toLower(subdomainInput);
    this.props.onSubdomainChange(subdomain);
    this.props.onValidationChange(false);
    this.checkSubdomainAvailability(subdomain);
  }
  checkSubdomainAvailability(subdomain) {
    const subdomainErrors = subdomainValidator(subdomain);
    if (isEmpty(subdomainErrors)) {
      this.executeCheck(subdomain);
    } else {
      this.setState({ subdomainErrors });
    }
  }
  executeCheck(subdomain) {
    signupApi.isSubdomainAvailable(subdomain, this.props.token)
      .then(asp(() => {
        if (subdomain === this.props.subdomain) {
          this.props.onValidationChange(true);
          this.setState({
            isSubdomainAvailable: true,
            subdomainErrors: [],
          });
        }
      }, this))
      .catch(asp(err => {
        let subdomainErrors = 'There was an error checking URL availability.';
        if (err.status === 400) {
          subdomainErrors = 'URL is unavailable.';
        }
        this.setState({
          subdomainErrors: [subdomainErrors],
        });
      }, this));
  }
  render() {
    return (
      <div className="subdomain-section-comp">
        <h1 className="subdomain-section-header">Create Your Workspace</h1>
        <div className="section-info">
            This space is for your team, so keep it relevant to your team alone. If you are part of a larger organization, this would be your department, not your company name.
        </div>
        <FormLabel label="pick your URL" />
        <div className="url-input-wrapper">
          <TextInput className="subdomain-input"
            placeholder="URL"
            value={this.props.subdomain}
            error={this.displaySubdomainErrors(this.state.subdomainErrors)}
            underlined
            onTextChange={this.onSubdomainChange} />
          <span className="gocious-domain">{GLOBAL_SEPHORA_URL_SUFFIX}</span>
          {this.state.isSubdomainAvailable &&
            <span className="check-mark-wrapper"><i className="icon-check subdomain-available-icon"></i></span>}
        </div>
      </div>
    );
  }
}

