import React from 'react';
import PropTypes from 'prop-types';

import Notification from 'common/Notification';
import { PROVISION_STATUS } from 'api/inviteApi';

const ErrorNotification = ({ errorType }) => {
  switch (errorType) {
    case PROVISION_STATUS.ERROR:
      return (
        <Notification className="general-error"
          header="Error provisioning your account...">
          <div className="message">
            There was an error provisioning your account. Please contact us for <a className="help-link" href="https://help.gocious.com/kb-tickets/new">assistance.</a>
          </div>
        </Notification>
      );
    case PROVISION_STATUS.CONTACT_ADMIN:
      return (
        <Notification className="contact-admin-error"
          header="Error completing your invitation..."
          message="Your invitation may have been revoked or has expired. Please contact your account administrator for a new invite link." />
      );
    default:
      return (
        <Notification className="retry-error"
          header="Error processing your request..."
          message="An error has occurred on our end. Please wait a few moments and refresh your browser to retry provisioning your account." />
      );
  }
};

ErrorNotification.propTypes = {
  errorType: PropTypes.oneOf([
    PROVISION_STATUS.ERROR,
    PROVISION_STATUS.CONTACT_ADMIN,
    PROVISION_STATUS.RETRY,
  ]),
};

export default ErrorNotification;

