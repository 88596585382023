import React, { useState }from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  some,
} from 'lodash';
import { FormSection, FormLabel } from '@gocious/aphrodite/dist/FormLayout';
import LoaderButton from '@gocious/aphrodite/dist/LoaderButton';
import ErrorMessage from '@gocious/aphrodite/dist/ErrorMessage';

import signupApi from 'api/signupApi';
import SubdomainSection from 'workgroups/SubdomainSection';
import PasswordSection from 'common/PasswordSection';
import passwordValidator from 'common/PasswordSection/passwordValidator';
import SelectCurrencyDropdown, { DEFAULT_CURRENCY } from 'common/SelectCurrencyDropdown';

import './AccountDetailsPanel.less';

export const AccountDetailsPanel = ({
  history,
  pathPrefix,
  token,
}) => {
  const [subdomain, setSubdomain] = useState('');
  const [isSubdomainValid, setIsSubdomainValid] = useState(false);
  const [subdomainChanged, setSubdomainChanged] = useState(false);
  const [password, setPassword] = useState('');
  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [createError, setCreateError] = useState('');

  const validatePassword = (password) => {
    const passwordValidationErrors = passwordValidator(password);
    const isValid = !some(passwordValidationErrors, error => error === true);
    setPassword(password);
    setIsPasswordValid(isValid);
    setCreateError('');
  };

  const createWorkSpace = () => {
    if (isSubdomainValid && !subdomainChanged) {
      setIsSaving(true);
      const payload = {
        subdomain,
        password,
        confirmationToken: token,
        baseCurrencyCode: currency.code,
      };
      signupApi.startWorkgroupProvisioning(payload)
        .then(() => {
          setIsSaving(false);
          history.push(`${pathPrefix}/account-status`);
        })
        .catch(() => {
          setIsSaving(false);
          setCreateError('There was an error creating your workspace.');
        });
    }
  };
  return (
    <div className="account-details-panel-comp">
      <FormSection>
        <SubdomainSection subdomain={subdomain}
          onSubdomainChange={(subdomain) => {
            setSubdomain(subdomain);
            setCreateError('');
            setSubdomainChanged(true);
          }}
          onValidationChange={(isSubdomainValid) => {
            setIsSubdomainValid(isSubdomainValid);
            setSubdomainChanged(false);
          }}
          token={token} />
      </FormSection>
      <FormSection>
        <div className="password-section-header">Secure Your Account</div>
        <FormLabel label="password" />
        <PasswordSection password={password}
          inputPlaceholder="Enter a Password"
          onPasswordChange={(password) => validatePassword(password)} />
      </FormSection>
      <FormSection>
        <div className="currency-section-header">Workspace Settings</div>
        <FormLabel label="primary currency" />
        <div className="dropdown-wrapper">
          <SelectCurrencyDropdown selectedCurrency={currency}
            onSelect={(currency) => setCurrency(currency)} />
        </div>
      </FormSection>
      <FormSection>
        <div className="create-btn-wrapper">
          <div className="terms-message">
            By clicking below to create your workspace, you're agreeing to our <a href={`${GLOBAL_MARKETING_DOMAIN}/terms`} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>.
          </div>
          <LoaderButton
            isDisabled={!isSubdomainValid || !isPasswordValid}
            onClick={createWorkSpace}
            isLoading={isSaving}
            label="Create Workspace" />
          {createError &&
            <div className="create-error">
              <ErrorMessage message={createError} />
            </div>}
        </div>
      </FormSection>
    </div>
  );
};

AccountDetailsPanel.propTypes = {
  history: PropTypes.object.isRequired,
  pathPrefix: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default withRouter(AccountDetailsPanel);
