import React from 'react';
import { Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Auth0Provider } from '@auth0/auth0-react';
import FontsGlobal from '@gocious/aphrodite/dist/FontsGlobal';
import IconsGlobal from '@gocious/aphrodite/dist/IconsGlobal';

import history from 'common/history';
import analytics from 'common/analytics';
import Routes from './views/Routes';
import LocaleManager from 'common/LocaleManager';
import Footer from 'common/Footer';
import getAuthConfig from 'common/auth/getAuthConfig';

import './App.less';

analytics.init(history);
const App = () => {
  const authConfig = getAuthConfig();
  const onRedirectCallback = (appState) => {
    if (appState?.returnTo) {
      history.replace(appState.returnTo);
    }
  };
  return (
    <div className="app-comp">
      {GLOBAL_HUBSPOT_ENABLED &&
        <Helmet>
          <script
            type="text/javascript"
            id="hs-script-loader"
            async defer src="https://js.hs-scripts.com/7003613.js"></script>
        </Helmet>}
      <FontsGlobal />
      <IconsGlobal />
      <LocaleManager>
        <>
          <Router history={history}>
            <Auth0Provider {...authConfig}
              onRedirectCallback={onRedirectCallback} >
              <Routes />
            </Auth0Provider>
          </Router>
          <Footer />
        </>
      </LocaleManager>
    </div>
  );
};
export default App;
