import {
  size,
  filter,
  includes,
} from 'lodash';

export default (transform) => {
  return (query='', list) => {
    if (size(list) === 0) {
      return [];
    }
    if (size(query) > 0) {
      const lowerQuery = query.toLowerCase();
      return filter(list, (item) => {
        const searchableItem = transform(item) || '';
        return includes(searchableItem.toLowerCase(), lowerQuery);
      });
    }
    return [
      ...list,
    ];
  };
};
