export const TOKEN_ERROR_MESSAGES = {
  Default: 'An unexpected error occurred, please try again',
  FailedThirdPartyAuthentication: 'Unable to connect to authentication service, please try again',
  TokenFailedValidation: 'Your invite has expired',
  GeneralInvalidInput: 'Your invite was revoked',
};

export const mapTokenError = type => {
  if (TOKEN_ERROR_MESSAGES[type]) {
    return TOKEN_ERROR_MESSAGES[type];
  }
  return TOKEN_ERROR_MESSAGES.Default;
};
