import React, { Component } from 'react';
import PropTypes from 'prop-types';

import createNewUserLink from 'common/createNewUserLink';
import inviteApi, { PROVISION_STATUS } from 'api/inviteApi';
import asyncSafe, {asyncSafePromise as asp} from '@gocious/async-safe';
import Notification from 'common/Notification';
import ErrorNotification from './ErrorNotification';
import Button, { TYPES } from '@gocious/aphrodite/dist/Button';
import './index.less';

const THREE_SECONDS = 3000;
const MAX_CHECK_STATUS_ATTEMPTS = 20;
export default class ProvisionStatus extends Component {
  constructor(props) {
    super(props);
    asyncSafe(this);
  }
  state = {
    siteName: '',
    hasTimedOut: false,
    inviteStatus: PROVISION_STATUS.PENDING,
  };

  checkStatusAttempts = 0;

  static propTypes = {
    token: PropTypes.string.isRequired,
    workgroupName: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.checkInviteStatus();
    this.startInterval();
  }
  componentWillUnmount() {
    clearInterval(this.checkStatusInterval);
  }
  startInterval() {
    this.checkStatusInterval = setInterval(asp(() => this.checkInviteStatus(), this), THREE_SECONDS);
  }
  checkInviteStatus() {
    if (this.checkStatusAttempts <= MAX_CHECK_STATUS_ATTEMPTS) {
      inviteApi.getUserAccountProvisioningStatus(this.props.token)
        .then(asp((res) => {
          this.updateStatus(res);
        }, this))
        .catch(asp(() => {
          clearInterval(this.checkStatusInterval);
          this.setState({ inviteStatus: PROVISION_STATUS.ERROR });
        }, this));
    } else {
      this.setState({
        hasTimedOut: true,
      });
    }
  }
  updateStatus(res) {
    if (!this.state.siteName) {
      this.setState({ siteName: res.siteName });
    }

    if (res.inviteStatus === PROVISION_STATUS.PENDING) {
      this.checkStatusAttempts += 1;
    } else {
      clearInterval(this.checkStatusInterval);
      this.setState({ inviteStatus: res.inviteStatus });
    }
  }
  renderStatus() {
    const {
      inviteStatus,
      hasTimedOut,
      siteName,
    } = this.state;
    const {
      workgroupName,
    } = this.props;
    if (hasTimedOut) {
      return (
        <Notification className="timed-out-page"
          header="Account provisioning has timed out. ">
          <div className="error-message">
            If the problem persists, please contact us for <a className="help-link" href="https://help.gocious.com/kb-tickets/new">assistance.</a>
          </div>
        </Notification>
      );
    }
    if (inviteStatus === PROVISION_STATUS.PENDING) {
      return (
        <Notification className="provision-pending-page"
          header={`We Are Adding You to ${workgroupName}`}
          message={`Sit tight. We are getting you ready to join ${workgroupName}.`}
          showLoader />
      );
    }
    if (inviteStatus === PROVISION_STATUS.COMPLETE) {
      return (
        <Notification className="provision-complete-page"
          header="All Set!"
          message={`You can now access ${siteName}${GLOBAL_SEPHORA_URL_SUFFIX}`}>
          <Button className="login-btn"
            type={TYPES.PRIMARY}
            href={createNewUserLink(siteName)}
            label="Login">
          </Button>
        </Notification>
      );
    }
    return <ErrorNotification className="provision-error-page"
      errorType={this.state.inviteStatus} />;
  }
  render() {
    return (
      <div className="provision-status-comp">
        {this.renderStatus()}
      </div>
    );
  }
}
