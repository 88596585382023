import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button, { TYPES } from '@gocious/aphrodite/dist/Button';
import asyncSafe, {asyncSafePromise as asp} from '@gocious/async-safe';
import createNewUserLink from 'common/createNewUserLink';

import signupApi from 'api/signupApi';
import Notification from 'common/Notification';

import './index.less';

const FIVE_SECONDS = 5000;
const WORKGROUP_PROVISIONING_STATUS = {
  ERROR: -1,
  PENDING: 2,
  COMPLETE: 3,
};
export default class AccountStatus extends Component {
  constructor(props) {
    super(props);
    asyncSafe(this);
  }
  state = {
    subdomainName: '',
    isPending: true,
    error: '',
  };
  static propTypes = {
    token: PropTypes.string,
  };
  componentDidMount() {
    this.checkWorkgroupStatus();
    this.startInterval();
  }
  componentWillUnmount() {
    clearInterval(this.checkStatusInterval);
  }
  startInterval() {
    this.checkStatusInterval = setInterval(asp(() => this.checkWorkgroupStatus(), this), FIVE_SECONDS);
  }
  checkWorkgroupStatus() {
    signupApi.getWorkgroupProvisioningStatus(this.props.token)
      .then(asp((res) => {
        this.updateStatus(res);
      }, this))
      .catch(asp(() => {
        clearInterval(this.checkStatusInterval);
        this.setState({
          isPending: false,
          error: 'There was an error provisioning your workspace. Please reload and try again. If this problem persists, please contact us for ',
        });
      }, this));
  }
  updateStatus(res) {
    if (!this.state.subdomainName) {
      this.setState({ subdomainName: res.subdomainName });
    }
    if (res.workgroupStatus === WORKGROUP_PROVISIONING_STATUS.COMPLETE) {
      this.setState({ isPending: false });
      clearInterval(this.checkStatusInterval);
    }
    if (res.workgroupStatus === WORKGROUP_PROVISIONING_STATUS.ERROR) {
      this.setState({
        isPending: false,
        error: 'There was an error provisioning your workspace. Please contact us for ',
      });
      clearInterval(this.checkStatusInterval);
    }
  }
  render() {
    const {
      isPending,
      error,
      subdomainName,
    } = this.state;
    return (
      <div className="account-status-comp">
        {isPending &&
          <Notification className="workspace-pending-page"
            header="We Are Building Your Workspace"
            message={subdomainName ? `We are finalizing ${subdomainName}${GLOBAL_SEPHORA_URL_SUFFIX}` : ''}
            showLoader />}
        {!isPending && error &&
          <Notification className="workspace-error-page"
            header="Error saving workspace...">
            <div className="error-message">
              {error}
              <a className="help-link"
                href="https://help.gocious.com/kb-tickets/new"
                target="_blank"
                rel="noopener noreferrer">
                  assistance.
              </a>
            </div>
          </Notification>}
        {!isPending && !error &&
          <Notification className="workspace-complete-page"
            header="All Set!">
            <Button className="login-btn"
              type={TYPES.PRIMARY}
              href={createNewUserLink(subdomainName)}
              label="Sign in to your workspace">
            </Button>
          </Notification>}
      </div>
    );
  }
}
