import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import BlockLoader from '@gocious/aphrodite/dist/BlockLoader';

const LoggedOut = () => {
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      appState: {
        returnTo: `signup${history.location.search}`,
      },
    });
  }, []);

  return <BlockLoader />;
};

export default LoggedOut;
