import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { FormLabel } from '@gocious/aphrodite/dist/FormLayout';
import TextInput from '@gocious/aphrodite/dist/TextInput';
import LoaderButton from '@gocious/aphrodite/dist/LoaderButton';
import Button, { TYPES } from '@gocious/aphrodite/dist/Button';
import emailValidator from '@gocious/email-validator';

import signupApi from 'api/signupApi';

import './index.less';

const ENTER_KEY = 13;

export const ERROR_CONTEXT = {
  EMAIL: 'SignupUserFunctions.CreateSignupUserAsync',
  PLAN: 'SubscriptionFunctions.GetSubscriptionPlanDetail',
};

const NewUserForm = ({
  planId,
  seatCount,
}) => {
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [customPlanId, setCustomPlanId] = useState('');

  const createAccount = () => {
    if (emailValidator(emailAddress)) {
      const userInfo = {
        email: emailAddress,
        planId: customPlanId || planId,
        seatCount,
      };
      setIsSaving(true);
      setError('');
      setCodeError('');
      signupApi.createSignupUser(userInfo)
        .then(() => {
          history.push('/check-email');
        })
        .catch(err => {
          let error = 'There was an error creating your account.';
          let codeError = '';
          if (err.status === 400) {
            if (err.data.context === ERROR_CONTEXT.EMAIL) {
              error = 'An account exists for this email.';
            }
            if (err.data.context === ERROR_CONTEXT.PLAN) {
              codeError = 'This subscription plan does not exist. Please check the promo code and try again.';
            }
          }
          setError(error);
          setCodeError(codeError);
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      setIsSaving(false);
      setError('Enter a valid email address');
    }
  };
  return (
    <div className="new-user-form-comp"
      onKeyUp={(e) => {
        e.keyCode === ENTER_KEY && createAccount();
      }}>
      <h1 className="email-panel-header">Enter Your Email Address to Get Started</h1>
      <FormLabel label="Email" />
      <div className="input-wrapper email">
        <TextInput placeholder="email@server.com"
          underlined
          saving={isSaving}
          value={emailAddress}
          error={error}
          onTextChange={(emailAddress) => {
            setEmailAddress(emailAddress);
            setError('');
          }} />
      </div>
      <div className="existing-user-button-wrapper">
        <Button type={TYPES.LINK}
          onClick={() => loginWithRedirect({
            appState: {
              returnTo: `signup${history.location.search}`,
            },
          })}
          label="Log in with an existing account" />
      </div>
      <FormLabel label="Promotional Code (OPTIONAL)" />
      <div className="input-wrapper code">
        <TextInput placeholder="Enter code here"
          underlined
          saving={isSaving}
          value={customPlanId}
          error={codeError}
          onTextChange={(customPlanId) => {
            setCustomPlanId(customPlanId);
            setError('');
          }} />
      </div>
      <div className="privacy-link">
        By clicking below, you agree to the Gocious <a href="https://gocious.com/privacy">Privacy Policy</a>.
      </div>
      <div className="button-section">
        <div className="get-started-button-wrapper">
          <LoaderButton
            onClick={createAccount}
            isLoading={isSaving}
            label="Get Started" />
        </div>
      </div>
    </div>
  );
};

NewUserForm.propTypes = {
  planId: PropTypes.string,
};

export default NewUserForm;
