import React from 'react';
import Button from '@gocious/aphrodite/dist/Button';
import { useAuth0 } from '@auth0/auth0-react';

import './index.less';

const SignInUserForm = () => {
  const authService = useAuth0();
  return (
    <div className="sign-in-user-form-comp" data-testid="sign-in-user-form-comp">
      <h1 className="header">Sign In to Get Started</h1>
      <div className="button-section">
        <div className="sign-in-button-wrapper">
          <Button
            onClick={authService.loginWithRedirect}
            label="Sign In" />
        </div>
      </div>
    </div>
  );
};

export default SignInUserForm;
