export default () => ({
  domain: GLOBAL_AUTH_DOMAIN,
  clientId: GLOBAL_AUTH_CLIENT_ID,
  authorizationParams: {
    audience: GLOBAL_AUTH_AUDIENCE,
    redirect_uri: window.location.origin + '/login',
    scope: 'openid profile offline_access',
  },
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  cacheLocation: 'localstorage',
});
