import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { FormSection, FormLabel } from '@gocious/aphrodite/dist/FormLayout';
import Button, { TYPES } from '@gocious/aphrodite/dist/Button';
import ErrorMessage from '@gocious/aphrodite/dist/ErrorMessage';
import LoaderButton from '@gocious/aphrodite/dist/LoaderButton';

import window from 'common/window';
import signupApi from 'api/signupApi';
import SelectCurrencyDropdown, { DEFAULT_CURRENCY } from 'common/SelectCurrencyDropdown';
import SubdomainSection from 'workgroups/SubdomainSection';
import './index.less';

export const LoggedInUserForm = ({
  planId,
  seatCount,
  userProfile,
  history,
}) => {
  const [subdomain, setSubdomain] = useState('');
  const [isSubdomainValid, setIsSubdomainValid] = useState(false);
  const [subdomainChanged, setSubdomainChanged] = useState(false);
  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const { logout } = useAuth0();
  const startWorkgroupCreation = () => {
    if (isSubdomainValid && !subdomainChanged) {
      setIsSaving(true);
      const provisioningPayload = {
        subdomain,
        planId,
        seatCount,
        baseCurrencyCode: currency.code,
      };
      signupApi.startWorkgroupProvisioning(provisioningPayload)
        .then(() => {
          setIsSaving(false);
          history.push('/create-workgroup-status');
        })
        .catch(() => {
          setIsSaving(false);
          setErrors({
            createError: 'There was an error creating your workspace.',
          });
        });
    }
  };
  const onChangeAccount = () => {
    logout({ logoutParams: { returnTo: `${window.location.origin}/logged-out${history.location.search}` } });
  };
  return (
    <div className="logged-in-user-form-comp">
      <FormSection>
        <SubdomainSection subdomain={subdomain}
          onSubdomainChange={(subdomain) => {
            setSubdomainChanged(true);
            setSubdomain(subdomain);
            setErrors({});
          }}
          onValidationChange={(isSubdomainValid) => {
            setSubdomainChanged(false);
            setIsSubdomainValid(isSubdomainValid);
          }} />
      </FormSection>
      <FormSection>
        <div className="currency-section-header">Workspace Settings</div>
        <FormLabel label="primary currency" />
        <div className="dropdown-wrapper">
          <SelectCurrencyDropdown selectedCurrency={currency}
            onSelect={(currency) => setCurrency(currency)} />
        </div>
      </FormSection>
      <FormSection>
        <FormLabel label="Admin Account" />
        <div className="admin-account-email">{userProfile.email}</div>
        <div className="change-account-button-wrapper">
          <Button type={TYPES.LINK}
            onClick={onChangeAccount}
            label="Use a different account" />
        </div>
      </FormSection>
      <div className="button-section">
        <div className="terms-message">
          By clicking below to create your workspace, you're agreeing to our <a href={`${GLOBAL_MARKETING_DOMAIN}/terms`} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>.
        </div>
        <div className="create-button-wrapper">
          <LoaderButton
            isDisabled={!isSubdomainValid || !subdomain}
            onClick={startWorkgroupCreation}
            isLoading={isSaving}
            label="Create Workspace" />
        </div>
      </div>
      {errors.createError &&
        <div className="create-error">
          <ErrorMessage message={errors.createError} />
        </div>}
    </div>
  );
};

LoggedInUserForm.propTypes = {
  planId: PropTypes.string.isRequired,
  seatCount: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
};

export default withRouter(LoggedInUserForm);
