import React from 'react';
import logo from 'images/logo.png';
import rocket from 'images/rocket.png';
import './index.less';

const GraphicPanel = () => {

  return (
    <div className="graphic-panel-comp" data-testid="graphic-panel-comp">
      <img className="logo" src={logo} />
      <h1 className="graphic-panel-header">You've got nothing to lose and everything to build</h1>
      <div className="content">Gocious combines creativity and analytics, helping companies with many possible product variants get to good decisions faster. Our Product Planning Software Platform is cloud-based and secure. We know you want to minimize production-level changes; we strive to help you do that and enjoy the journey.</div>
      <img className="panel-image" src={rocket} />
    </div>
  );
};

export default GraphicPanel;

