import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.less';

export default class AppLayout extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };
  render() {
    return (
      <div className="app-layout-comp">
        <div className="main-wrapper">
          {this.props.children}
        </div>
      </div>
    );
  }
}
