import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';

import asyncSafe, {asyncSafePromise as asp} from '@gocious/async-safe';
import signupApi from 'api/signupApi';
import Notification from 'common/Notification';
import AccountDetails from './AccountDetails';
import AccountStatus from 'workgroups/AccountStatus';
import './index.less';
import Button from '@gocious/aphrodite/dist/Button';

export class VerificationRouter extends Component {
  constructor(props) {
    super(props);
    asyncSafe(this);
  }
  state = {
    isValidating: true,
    tokenError: '',
  };
  static propTypes = {
    history: PropTypes.object.isRequired,
    pathPrefix: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  };
  componentDidMount() {
    this.validateToken();
  }
  validateToken() {
    signupApi.isValidSignupToken(this.props.token)
      .then(asp(() => {
        this.setState({ isValidating: false });
      }, this))
      .catch(asp(err => {
        this.setState({ isValidating: false });
        this.handleTokenError(err.status);
      }, this));
  }
  handleTokenError(error) {
    let tokenError = 'Something went wrong';
    if (error === 400) {
      tokenError = 'Signup link is invalid. Are you using an old link?';
    }
    if (error === 401) {
      tokenError = 'Signup link is expired';
    }
    this.setState({
      tokenError,
    });
  }
  handleGoToSignUp() {
    this.props.history.push('/');
  }
  render() {
    const {
      isValidating,
      tokenError,
    } = this.state;
    const {
      pathPrefix,
      token,
    } = this.props;
    if (isValidating) {
      return <div className="signup-notification-comp">
        <Notification className="validation-pending-screen"
          header="Validation in progress..."
          message="Sit tight. We are validating your signup link."
          showLoader />
      </div>;
    }
    if (tokenError) {
      return <div className="signup-notification-comp">
        <Notification className="token-error"
          header={tokenError}
          message="Return to the signup screen to obtain a new link." />
        <Button onClick={this.handleGoToSignUp}
          label="Go to Sign Up" />
      </div>;
    }
    return (
      <Switch>
        <Route exact
          path={`${pathPrefix}/account-details`}
          render={() => (
            <AccountDetails pathPrefix={pathPrefix}
              token={token} />
          )} />
        <Route exact
          path={`${pathPrefix}/account-status`}
          render={() => (
            <AccountStatus token={token} />
          )} />
        <Route render={() => (
          <Redirect to={`${pathPrefix}/account-details`} />
        )} />
      </Switch>
    );
  }
}

export default withRouter(VerificationRouter);
