import React from 'react';
import ReactDOM from 'react-dom';
import printBuildNumber from '@gocious/print-build-number';

import App from './App';
import window from 'common/window';

printBuildNumber(window.console, process.env.BUILD_NUMBER);
ReactDOM.render(
  <App />,
  document.getElementById('app')
);
