import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AppLayout from 'common/AppLayout';
import Auth from 'common/auth/Auth';
import SignUp from './SignUp';
import CheckEmail from './CheckEmail';
import JiraRedirect from './JiraRedirect';
import LoggedOut from './LoggedOut';
import VerificationRouter from './VerificationRouter';
import InviteVerificationRouter from './InviteVerificationRouter';
import RouteErrorBoundary from 'common/RouteErrorBoundary';
import AccountStatus from 'workgroups/AccountStatus';
import Login from './Login';

const Routes = () => {
  return (
    <RouteErrorBoundary>
      <AppLayout>
        <Switch>
          <Route
            path="/verify/:token"
            render={({ match }) => (
              <VerificationRouter pathPrefix={match.url}
                token={match.params.token} />
            )} />
          <Route
            path="/verify-invite/:token"
            render={({ match }) => (
              <InviteVerificationRouter pathPrefix={match.url}
                token={match.params.token} />
            )} />
          <Route
            path="/jira-redirect"
            render={({ location }) => <JiraRedirect searchParams={location.search} />} />
          <Route exact
            path="/check-email"
            component={CheckEmail} />
          <Route render={() => (
            <Auth render={() => (
              <Switch>
                <Route exact
                  path="/"
                  component={Login} />
                <Route exact
                  path="/signup"
                  component={SignUp} />
                <Route exact
                  path="/create-workgroup-status"
                  component={AccountStatus} />
                <Route exact
                  path="/logged-out"
                  component={LoggedOut} />
                <Route render={() => (
                  <Redirect to="/" />
                )} />
              </Switch>
            )} />
          )} />
        </Switch>
      </AppLayout>
    </RouteErrorBoundary>
  );
};
export default Routes;
