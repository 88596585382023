import {
  get,
} from 'lodash';
import window from 'common/window';

export default () => {
  var lang = get(window.navigator, 'languages[0]');
  if (lang) {
    return lang;
  }
  return window.navigator.userLanguage || window.navigator.browserLanguage || window.navigator.language;
};
