import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import autoBind from 'auto-bind';
import { some } from 'lodash';

import TextInput from '@gocious/aphrodite/dist/TextInput';
import passwordValidator from './passwordValidator';
import './index.less';

export default class PasswordSection extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  state = {
    passwordValidationErrors: {},
    viewPassword: false,
  };
  static propTypes = {
    password: PropTypes.string.isRequired,
    inputPlaceholder: PropTypes.string,
    onPasswordChange: PropTypes.func.isRequired,
    centerJustified: PropTypes.bool,
    error: PropTypes.string,
  };
  static defaultProps = {
    inputPlaceholder: 'Enter a password',
  };
  toggleViewPassword() {
    const viewPassword = !this.state.viewPassword;
    this.setState({ viewPassword });
  }
  validatePassword(password) {
    const passwordValidationErrors = passwordValidator(password);
    this.setState({
      passwordValidationErrors,
    });
    this.props.onPasswordChange(password);
  }
  render() {
    const {
      viewPassword,
      passwordValidationErrors,
    } = this.state;
    const {
      password,
      error,
      centerJustified,
      inputPlaceholder,
    } = this.props;
    const isValidPassword = password && !some(passwordValidationErrors, (error => error === true));
    return (
      <div className={cn('password-section-comp', {
        'center-justified': centerJustified,
      })}>
        <div className='password-input-wrapper'>
          <TextInput className="password-input"
            type={viewPassword ? 'text' : 'password'}
            placeholder={inputPlaceholder}
            value={password}
            error={error}
            underlined
            onTextChange={this.validatePassword} />
          <a className="view-password-btn"
            onClick={this.toggleViewPassword}>
            {viewPassword &&
              <i className="icon-eye-slash hide-password-icon"></i>}
            {!viewPassword &&
              <i className="icon-eye view-password-icon"></i>}
          </a>
          <span className="check-mark-wrapper">
            {isValidPassword &&
              <i className="icon-check password-valid-icon"></i>}
          </span>
        </div>
        <div className="password-validation-wrapper">
          <ul className="validation-conditions">
            <li className={cn('validation-list-item', 'min-chars-condition', {
              'is-valid': passwordValidationErrors.lessThanEightChars === false,
            })}>Minimum 8 characters</li>
            <li className={cn('validation-list-item', 'three-identical-consec-chars-condition', {
              'is-valid': passwordValidationErrors.moreThanTwoIdenticalConsecChars === false,
            })}>No more than two identical characters in a row</li>
            <li className={cn('validation-list-item', 'needs-upper-case-condition', {
              'is-valid': passwordValidationErrors.needsUpperCase === false,
            })}>Upper and lower case letters</li>
            <li className={cn('validation-list-item', 'needs-symbol-condition', {
              'is-valid': passwordValidationErrors.needsSymbol === false,
            })}>At least one symbol (!@#$%^&*)</li>
            <li className={cn('validation-list-item', 'needs-digit-condition', {
              'is-valid': passwordValidationErrors.needsDigit === false,
            })}>At least 1 digit</li>
          </ul>
        </div>
      </div>
    );
  }
}
