import React from 'react';

import Notification from 'common/Notification';
import './index.less';

const CheckEmail = () => {
  return <div className="check-email-notification-comp">
    <Notification header="We've sent you an email"
      message="Please check your email for the confirmation link we have sent you to continue creating your account." />
  </div>;
};

export default CheckEmail;
