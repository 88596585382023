import React from 'react';
import { pick } from 'lodash';

import GraphicPanel from 'common/GraphicPanel';
import AccountDetailsPanel from './AccountDetailsPanel';
import './index.less';

const PASSTHROUGH_PROP_TYPES = pick(AccountDetailsPanel.propTypes, [
  'pathPrefix',
  'token',
]);

const AccountDetails = ({ pathPrefix, token }) => {
  return (
    <div className="account-details-comp">
      <div className="graphic-panel-wrapper">
        <GraphicPanel />
      </div>
      <div className="account-details-panel-wrapper">
        <AccountDetailsPanel pathPrefix={pathPrefix}
          token={token} />
      </div>
    </div>
  );
};

AccountDetails.propTypes = {
  ...PASSTHROUGH_PROP_TYPES,
};

export default AccountDetails;
