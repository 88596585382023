import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

import window from 'common/window';
import Notification from 'common/Notification';

const JiraRedirect = ({ searchParams }) => {
  const [error, setError] = useState('');

  useEffect(() => {
    const decodedParams = decodeURIComponent(searchParams);
    const {
      state: workspace,
      connectionId,
      code,
    } = qs.parse(decodedParams);
    const isValidRedirect = workspace && code;
    if (isValidRedirect) {
      const encodedWorkspace = encodeURIComponent(workspace);
      const encodedCode = encodeURIComponent(code);
      const query = qs.stringify({ connectionId });
      window.location.href = `https://${encodedWorkspace}${GLOBAL_SEPHORA_URL_SUFFIX}/integrations/jira-connection/${encodedCode}?${query}`;
    } else {
      setError('Redirect URL was not provided. Please contact your administrator if you continue to receive this error.');
    }
  }, []);
  return (
    <div className="jira-redirect-comp">
      {error &&
        <Notification className="redirect-error"
          header="Failed to redirect"
          message={error} />}
    </div>
  );
};

JiraRedirect.propTypes = {
  searchParams: PropTypes.string.isRequired,
};

export default JiraRedirect;
