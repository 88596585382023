import ajaxHelper from '@gocious/ajax-helper';

export const PROVISION_STATUS = {
  ERROR: -1,
  PENDING: 2,
  COMPLETE: 3,
  CONTACT_ADMIN: 4,
  RETRY: 5,
};

export default class inviteApi {
  static isValidInviteToken(token) {
    return ajaxHelper.get(`${GLOBAL_APIM_HOST}/api/users/tokens/invite/isvalid?token=${token}`);
  }
  static registerUserWithWorkgroup(userAccountInfo) {
    return ajaxHelper.post(`${GLOBAL_APIM_HOST}/api/users/workgroup/register`, userAccountInfo);
  }
  static startUserAccountProvisioning(userAccountInfo) {
    return ajaxHelper.post(`${GLOBAL_APIM_HOST}/api/users/account/provision`, userAccountInfo);
  }
  static getUserAccountProvisioningStatus(token) {
    return ajaxHelper.get(`${GLOBAL_APIM_HOST}/api/users/account/provision?token=${token}`);
  }
}
