import React, { useState, useEffect }from 'react';
import PropTypes from 'prop-types';
import {
  map,
  sortBy,
} from 'lodash';
import SingleSelectDropdown from '@gocious/aphrodite/dist/SingleSelectDropdown';

import listFilterByText from 'common/listFilterByText';
import currenciesApi from 'api/currenciesApi';

const currencyFilter = listFilterByText(currency => currency.name);

export const DEFAULT_CURRENCY = {
  name: 'United States Dollar',
  code: 'USD',
  id: 'USD',
};

const SelectCurrencyDropdown = ({ onSelect, selectedCurrency }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [filteredCurrencies, setFilteredCurrencies] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    currenciesApi.getGlobalCurrencies()
      .then(currencies => {
        setIsLoading(false);
        const formattedCurrencies = map(currencies, (currency) => ({
          id: currency.code,
          ...currency,
        }));
        const sortedCurrencies = sortBy(formattedCurrencies, 'name');
        setCurrencies(sortedCurrencies);
        setFilteredCurrencies(sortedCurrencies);
      })
      .catch(() => {
        setIsLoading(false);
        setLoadError('There was an error loading the list of currencies.');
      });
  }, []);

  const changeSearch = (query) => {
    setQuery(query);
    setFilteredCurrencies(currencyFilter(query, currencies));
  };

  const toggleDropdown = () => {
    !isDropdownOpen && setQuery('');
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <SingleSelectDropdown isLoading={isLoading}
      loadError={loadError}
      isOpen={isDropdownOpen}
      onToggle={toggleDropdown}
      label={selectedCurrency.name}
      selectedItem={selectedCurrency}
      onSelectItem={(selectedCurrency) => {
        setIsDropdownOpen(false);
        onSelect(selectedCurrency);
      }}
      listItems={filteredCurrencies}
      customDisplayName={(currency) => `${currency.name} (${currency.code})`}
      onChangeSearch={changeSearch}
      query={query} />
  );
};

SelectCurrencyDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedCurrency: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.name,
  }).isRequired,
};

export default SelectCurrencyDropdown;

