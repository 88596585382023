export const PLANS = {
  FREE_90: {
    id: '3dc12101-383f-43a5-a71f-721b41cf65e7',
    label: '90 Day Free Trial Plan',
  },
  FREE_60: {
    id: '42f3e2e3-1f14-4069-904e-7cf880205fe5',
    label: '60 Day Free Trial Plan',
  },
  FREE_30: {
    id: '262a1b20-061d-46df-ab71-7132d0359695',
    label: '30 Day Free Trial Plan',
  },
  FREE_14: {
    id: '5e998ec9-db13-46e7-a9ec-e9b0a2e8f287',
    label: '14 Day Free Trial Plan',
  },
  FREE_1: {
    id: '1abaeb5c-eb25-41f7-ad9b-080c3527c685',
    label: '14 Day Free Trial Plan',
  },
  ANNUAL: {
    id: '8ce21c00-7dd0-4739-9632-7b29342ee868',
    label: 'Annual Plan',
  },
  MONTHLY: {
    id: 'c411268c-cb8a-4aad-83ad-32d0147a7733',
    label: 'Monthly Plan',
  },
  ENTERPRISE: {
    id: 'd1f91ad1-ce3e-4fb6-a2a5-c8d2e17ef09f',
    label: 'Enterprise Plan',
  },
};
