import React from 'react';
import { DateTime } from 'luxon';

import './index.less';

export default function Footer() {
  return (
    <div className="footer-comp">
      <div className="footer-copyright">
        <a href="https://gocious.com" className="marketing-link">Gocious </a>
        <span className="copyright-year">
          &copy; {DateTime.now().toFormat('y')}
        </span>
      </div>
      <div className="footer-links">
        <a href="https://help.gocious.com/"
          target="_blank" rel="noopener noreferrer"
          className="help-link">Help</a>
      </div>
    </div>
  );
}
