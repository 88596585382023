import React from 'react';
import PropTypes from 'prop-types';
import UserContext from 'common/UserContext';
import LoggedInUserForm from 'common/LoggedInUserForm';


function ExistingUserForm({
  planId,
  seatCount,
  unknownUserRender,
}) {

  const renderExistingUserFlow = () => {
    return <UserContext.Consumer>
      {({ user }) => {
        if (user.userProfile.id) {
          return <LoggedInUserForm planId={planId}
            seatCount={seatCount}
            userProfile={user.userProfile} />;
        }
        return unknownUserRender();
      }}
    </UserContext.Consumer>;
  };

  return (
    <div className="user-validator-comp">
      {renderExistingUserFlow()}
    </div>
  );
};

ExistingUserForm.propTypes = {
  planId: PropTypes.string.isRequired,
  seatCount: PropTypes.number.isRequired,
  unknownUserRender: PropTypes.func.isRequired,
};

export default ExistingUserForm;
