import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  some,
} from 'lodash';

import inviteApi from 'api/inviteApi';
import asyncSafe, {asyncSafePromise as asp} from '@gocious/async-safe';
import { FormSection } from '@gocious/aphrodite/dist/FormLayout';
import ErrorMessage from '@gocious/aphrodite/dist/ErrorMessage';
import LoaderButton from '@gocious/aphrodite/dist/LoaderButton';
import logo from 'images/logo.png';
import PasswordSection from 'common/PasswordSection';
import passwordValidator from 'common/PasswordSection/passwordValidator';
import './index.less';

const ENTER_KEY = 13;

class NewUserDetails extends Component {
  constructor(props) {
    super(props);
    asyncSafe(this);
  }
  static propTypes = {
    history: PropTypes.object.isRequired,
    pathPrefix: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    eTag: PropTypes.string.isRequired,
  };
  state = {
    password: '',
    passwordError: '',
    isSaving: false,
    createError: '',
  };
  validForm() {
    const passwordValidationErrors = passwordValidator(this.state.password);
    const isPasswordValid = !some(passwordValidationErrors, (error => error === true));
    return isPasswordValid;
  }
  displayValidationError() {
    this.setState({ passwordError: 'Enter a valid password' });
  }
  startUserAccountProvisioning() {
    if (this.validForm()) {
      this.setState({ isSaving: true });
      const newAccountInfo = {
        token: this.props.token,
        eTag: this.props.eTag,
        password: this.state.password,
      };
      inviteApi.startUserAccountProvisioning(newAccountInfo)
        .then(asp(() => {
          this.setState({ isSaving: false });
          this.props.history.push(`${this.props.pathPrefix}/account-status`);
        }, this))
        .catch(asp(() => {
          this.setState({
            isSaving: false,
            createError: 'There was an error provisioning your account.',
          });
        }, this));
    } else {
      this.displayValidationError();
    }
  }
  render() {
    const {
      password,
      passwordError,
      isSaving,
      createError,
    } = this.state;
    return (
      <div className="new-user-details-comp"
        onKeyUp={(e) => {
          e.keyCode === ENTER_KEY && this.startUserAccountProvisioning();
        }} >
        <div className="user-details-wrapper">
          <img className="logo" src={logo} />
          <h1 className="user-details-header">Secure Your Account</h1>
          <div className="user-details-message">Please create a password for your account</div>
          <FormSection className="password-section-wrapper">
            <div className="password-section-header">Enter a Password</div>
            <PasswordSection password={password}
              centerJustified
              error={passwordError}
              inputPlaceholder="Password"
              onPasswordChange={(password => this.setState({
                password,
                passwordError: '',
                createError: '',
              }))} />
          </FormSection>
          <FormSection>
            <div className="create-account-btn-wrapper">
              <LoaderButton
                onClick={this.startUserAccountProvisioning}
                isLoading={isSaving}
                label="continue" />
              {createError &&
                <div className="create-error">
                  <ErrorMessage message={createError} />
                </div>}
            </div>
          </FormSection>
        </div>
      </div>
    );
  }
}

export default withRouter(NewUserDetails);
