import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import BlockLoader from '@gocious/aphrodite/dist/BlockLoader';
import { contentSidePadding } from '@gocious/aphrodite/dist/structure';
import { purple } from '@gocious/aphrodite/dist/colors';
import {
  fontBody,
  fontHeadline,
} from '@gocious/aphrodite/dist/font';

import logo from 'images/logo.png';

const _NotificationDiv = styled.div`
  display: flex;
  padding: 0 ${contentSidePadding*2}px;
  height: 100%;
  font-size: 14px;
  font-family: ${fontBody};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const _HeaderH1 = styled.h1`
  font-family: ${fontHeadline};
  font-size: 30px;
  margin-bottom: 10px;
  color: ${purple};
  font-weight: normal;
  ${({bold}) => bold && css`
    font-weight: bold;
    font-size: 38px;
  `}
`;

const Notification = ({header, boldHeader, message, showLoader, children}) => {
  return (
    <_NotificationDiv className="notification-comp">
      <img className="logo"
        src={logo}
        css="width: 175px;"/>
      {showLoader &&
        <BlockLoader />}
      {header &&
        <_HeaderH1 className="header"
          bold={boldHeader}>
          {header}
        </_HeaderH1>}
      {message &&
        <div className="message"
          css={`
            font-size: 16px;
            margin-bottom: ${contentSidePadding*1.5}px;
          `}>
          {message}
        </div>}
      <div>{children}</div>
    </_NotificationDiv>
  );
};

Notification.propTypes = {
  header: PropTypes.string.isRequired,
  boldHeader: PropTypes.bool,
  message: PropTypes.string,
  showLoader: PropTypes.bool,
  children: PropTypes.node,
};

Notification.defaultProps = {
  showLoader: false,
  boldHeader: false,
};

export default Notification;

