import queryString from 'query-string';
import ajaxHelper from '@gocious/ajax-helper';

export default class signupApi {
  static createSignupUser(userInfo) {
    return ajaxHelper.post(`${GLOBAL_APIM_HOST}/api/users/signup`, userInfo);
  }
  static isValidSignupToken(token) {
    const qs = queryString.stringify({token});
    return ajaxHelper.get(`${GLOBAL_APIM_HOST}/api/users/tokens/signup/isvalid?${qs}`);
  }
  static isSubdomainAvailable(subdomain, token) {
    const qs = queryString.stringify({token});
    return ajaxHelper.get(`${GLOBAL_APIM_HOST}/api/workgroups/subdomain/${subdomain}?${qs}`);
  }
  static startWorkgroupProvisioning(subdomainDetails) {
    return ajaxHelper.post(`${GLOBAL_APIM_HOST}/api/users/workgroup/provision`, subdomainDetails);
  }
  static getWorkgroupProvisioningStatus(token) {
    const qs = queryString.stringify({token});
    return ajaxHelper.get(`${GLOBAL_APIM_HOST}/api/workgroups/provision?${qs}`);
  }
}
