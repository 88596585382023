import {
  size,
  startsWith,
  endsWith,
} from 'lodash';

export default (subdomain) => {
  const errors = [];
  const alphaNumeric = /^[a-z0-9-]+$/i;
  const consecDashes = /--/;

  if (size(subdomain) < 3) {
    errors.push('Your URL must be at least 3 characters.');
  }
  if (size(subdomain) > 25) {
    errors.push('Your URL can have a maximum of 25 characters.');
  }
  if (!alphaNumeric.test(subdomain)) {
    errors.push('Your URL can only contain letters, numbers, and dashes.');
  }
  if (consecDashes.test(subdomain)) {
    errors.push('Your URL cannot contain consecutive dashes.');
  }
  if (startsWith(subdomain, '-') || endsWith(subdomain, '-')) {
    errors.push('Your URL cannot start or end with a dash.');
  }
  return errors;
};
