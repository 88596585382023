import React from 'react';

import GraphicPanel from 'common/GraphicPanel';
import ExistingUserForm from 'common/ExistingUserForm';
import SignInUserForm from './SignInUserForm';
import usePlanInfo from 'common/usePlanInfo';

import './index.less';

function Login() {
  const{
    planId,
    seatCount,
  } = usePlanInfo();

  return (
    <div className="sign-up-comp">
      <div className="graphic-panel-wrapper">
        <GraphicPanel />
      </div>
      <div className="email-panel-wrapper">
        <ExistingUserForm planId={planId}
          seatCount={seatCount}
          unknownUserRender={() => <SignInUserForm />} />
      </div>
    </div>
  );
};

export default Login;
