import React, { Component } from 'react';
import PropTypes from 'prop-types';

import createNewUserLink from 'common/createNewUserLink';
import inviteApi from 'api/inviteApi';
import asyncSafe, {asyncSafePromise as asp} from '@gocious/async-safe';
import Notification from 'common/Notification';
import Button, { TYPES } from '@gocious/aphrodite/dist/Button';
import './index.less';

export default class WorkgroupRegistration extends Component {
  constructor(props) {
    super(props);
    asyncSafe(this);
  }
  state = {
    siteName: '',
    isPending: true,
    error: false,
  };
  static propTypes = {
    token: PropTypes.string.isRequired,
    eTag: PropTypes.string.isRequired,
    workgroupName: PropTypes.string.isRequired,
  };
  componentDidMount() {
    this.registerUserWithWorkgroup();
  }
  registerUserWithWorkgroup()  {
    const userDetails = {
      token: this.props.token,
      eTag: this.props.eTag,
    };
    inviteApi.registerUserWithWorkgroup(userDetails)
      .then(asp((res) => {
        this.setState({
          siteName: res.siteName,
          isPending: false,
        });
      }, this))
      .catch(asp(() => {
        this.setState({
          isPending: false,
          error: true,
        });
      }, this));
  }
  render() {
    const {
      isPending,
      error,
      siteName,
    } = this.state;
    const { workgroupName } = this.props;
    return (
      <div className="workgroup-registration-comp">
        {isPending &&
          <Notification className="registration-pending-page"
            header={`We Are Adding You to ${workgroupName}`}
            message={`Sit tight. We are getting you ready to join ${workgroupName}.`}
            showLoader />}
        {!isPending && error &&
          <Notification className="registration-error-page"
            header="Error registering your account...">
            <div className="error-message">
              {`An error occurred while trying to register you to ${workgroupName}. Please reload and try again. If this problem persists, please contact us for `}
              <a className="help-link"
                href="https://help.gocious.com/kb-tickets/new"
                target="_blank"
                rel="noopener noreferrer">
                  assistance.
              </a>
            </div>
          </Notification>}
        {!isPending && !error &&
          <Notification className="registration-complete-page"
            header="All Set!"
            message={`You can now access ${siteName}${GLOBAL_SEPHORA_URL_SUFFIX}`}>
            <Button className="login-btn"
              type={TYPES.PRIMARY}
              href={createNewUserLink(siteName)}
              label="Login">
            </Button>
          </Notification>}
      </div>
    );
  }
}
